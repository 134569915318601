<template>
  <div class="content-body">
      <div class="academic-container">
        <div class="row mg-0">
        <div class="col-sm-5">
            <div class="content-header pd-l-0">
            <div>
                <h4 class="content-title content-title-xs">Manage Customer</h4>
            </div>
            </div>
            <!-- content-header -->
        </div>
        </div>
        <!-- row -->
        <div class="row my-2">
            <div class="filter-input col-md-9 d-flex mr-0 pr-0">
            <select v-model="filter.size">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
            </select>
            </div>
            <div class="col-md-3 ml-0 pl-0">
            <input type="text" v-model="filter.key"  class="float-right px-1" placeholder="Search...">
            </div>
        </div>
        <div class="row py-0">
        <div class="col-md-12 year-transfer-table-body">
            <table class="table table1 table-hover" id="tableData">
            <thead>
                <tr>
                <th class="wd-5p table-start-item">S.N</th>
                <th class="wd-10p">Customer ID</th>
                <th class="wd-25p">Customer Name</th>
                <th class="wd-20p">Address</th>
                <th class="wd-10p">Email</th>
                <th class="wd-10p">Contact No.</th>
                <th class="wd-10p">Due Amt.</th>
                <th class="wd-10p text-right table-end-item">Action</th>
                </tr>
            </thead>
            <tbody v-for="(customer,index) in dataSets" :key="index">
            <tr v-if="!removeList.includes(customer.id)">
                <th scope="row" class="table-start-item">{{++index}}</th>
                <td>CUST{{threeDigit(customer.id)}}</td>
                <td>{{customer.name}}</td>
                <td>{{customer.address}}</td>
                <td>{{customer.email}}</td>
                <td>{{customer.phone}}</td>
                <td>{{customer.opening_due}}</td>
                <td class="text-right table-end-item">
                    <a href="javascript:;" @click="drop(customer.id)">
                    <i class="fa fa-trash"></i>
                    </a>
                </td>
                </tr>
            </tbody>
            </table>
            <Pagination />
        </div>
        <div class="col-md-12 text-right mt-3">
            <button type="submit" class="btn btn-sm btn-primary" @click="$router.push(`/academic-year-transfer/supplier`)">Next</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import {mapGetters} from "vuex";
import {Search} from "../../../../../../../mixins/search";
import Pagination from "../../../../../components/pagination/pagination";
export default {
  mixins: [Search],
  components: { Pagination},
  methods: {
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", {id:modal,mode:'edit'});
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/customer/${id}`);
    },getData(){
      this.$store.commit("getData", `api/customers/size/${this.filter.size}`);
    }
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>